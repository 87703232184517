const _defineFunctionIfMissing = (obj, funName, fun) => {
    if (typeof obj === 'object' && !Object.prototype.hasOwnProperty.call(obj, funName)) {
        obj[funName] = fun;
    }
};

export const polyfillTHREE = (THREE) => {
    // Backport from R86: https://github.com/mrdoob/three.js/commit/e2f465d2fbf504c08f78a3653962b1caf7cd60e1
    _defineFunctionIfMissing(THREE?.Camera?.prototype, "updateMatrixWorld", function ( force ) {
    
        THREE.Object3D.prototype.updateMatrixWorld.call( this, force );
    
        this.matrixWorldInverse.getInverse( this.matrixWorld );
    
    });
    
    _defineFunctionIfMissing(THREE, "WebGLProperties", function WebGLProperties() {
        function get() { return {}; }
        return { get };
    });
    
    if (THREE?.BufferGeometry?.prototype) {
        Object.defineProperty(THREE?.BufferGeometry?.prototype, 'groups', {
            get: function get() {		
                return this.offsets;
            }
        });
    }

    // Polyfill for r113 addition of Frustum.setFromProjectionMatrix https://github.com/mrdoob/three.js/commit/da2c0affd1c2cb21f7d5ecd67aaf5138221cd367
    _defineFunctionIfMissing(THREE?.Frustum?.prototype, "setFromProjectionMatrix", function (m) {
        return this.setFromMatrix(m);
    });
};
